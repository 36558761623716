import React from 'react';
import './ErrorDisplay.css';

const ErrorDisplay = (props: any) => (
  <div className="result-display">
    <div className="quote-container absolute z-30 w-full">
      <div className="quote flex h-screen justify-center items-center text-white">
        <div className="load-text m-10">
          <div className="text-2xl max-w-prose">
           {props.text}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorDisplay;
