import React from 'react';
import QRCode from "react-qr-code";
import './Quote.css';

const Quote = (props: any) => (
  <div className="result-display">
    <div className="quote-container absolute z-30 w-full">
      <div className="quote flex h-screen justify-center items-center text-white">
        <div className="load-text m-10">
          <div className="quote-text text-4xl max-w-prose">
           "{props.text}"
          </div>
          <div className="attribution opacity-75 hover:opacity-100"> <br/>-{props.attribution}</div>
        </div>
      </div>
    </div>
    <div className="load-more feedback-container absolute bottom-20 left-20 z-20 bg-white rounded-lg p-3 flex flex-col invisible md:visible">
      <div className="qr-code-wrapper">
        <QRCode value={props.moreLink} size={128} />
      </div>
      <div className="feedback-text text-center italic mt-4 text-gray-600">Scan for More <br/>&amp; Attribution</div>
    </div>
  </div>

);

export default Quote;
