import React from 'react';
import SkyBackground from './components/SkyBackground/SkyBackground';
import Quote from './components/Quote/Quote';
import logo from './logo-white-transparent.png';
import './App.css';
import Webcam from "react-webcam";

import * as faceapi from 'face-api.js';
import ErrorDisplay from './components/ErrorDisplay/ErrorDisplay';

class QuoteItem {
  quote: string;
  author: string;
  moreLink : string;
  age: number;
  angry: number;
  disgusted: number;
  fearful: number;
  happy: number;
  neutral: number;
  sad: number;
  surprised: number;

  constructor(quote:string, author:string, moreLink:string, age:number, angry:number, disgusted:number, fearful:number, happy:number, neutral:number, sad:number, surprised:number) {
    this.quote = quote;
    this.author = author;
    this.age = age;
    this.angry = angry;
    this.disgusted = disgusted;
    this.fearful = fearful;
    this.happy = happy;
    this.neutral = neutral;
    this.sad = sad;
    this.surprised = surprised;
    this.moreLink = moreLink;
  }

  distanceTo(age:number, angry:number, disgusted:number, fearful:number, happy:number, neutral:number, sad:number, surprised:number) {
    let distance = Math.pow(Math.abs(this.age/100 - age/100),2) + Math.pow(Math.abs(this.angry - angry),2) + Math.pow(Math.abs(this.disgusted - disgusted),2) + Math.pow(Math.abs(this.fearful - fearful),2) + Math.pow(Math.abs(this.happy - happy),2) + Math.pow(Math.abs(this.neutral - neutral),2) + Math.pow(Math.abs(this.sad - sad),2) + Math.pow(Math.abs(this.surprised - surprised),2);
    return distance;
  }
}

class AppState { 
  selectedQuote?: QuoteItem;
  errorText?: string;
  xNorm: number = 0.75;
  yNorm: number = 0.75;
}

class App extends React.Component<{},AppState> {

  state = new AppState();

  webcam: any;

  webcamIssue: boolean = false;

  missingFaceCycles : number = 0;

  modelUrl : string = '/models';

  quoteItems: QuoteItem[] = [ new QuoteItem("Become aware of your thoughts and emotions in these moments, just observing them with kindness, without judging them or yourself.","Jon Kabat-Zinn, Ph.D (Full Catastrophe Living)","https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness-ebook/dp/B00C4BA3UK",38,0.9,0.5,0.5,0.5,0.5,0.5,0.6),
  new QuoteItem("The richness of present-moment experience is the richness of life itself.","Jon Kabat-Zinn, Ph.D (Full Catastrophe Living)","https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness-ebook/dp/B00C4BA3UK",30,0.5,0.5,0.1,1,0,0.5,0.75),
  new QuoteItem("It is impossible to become like somebody else. Your only hope is to become more fully yourself. That is the reason for practicing meditation in the first place.","Jon Kabat-Zinn, Ph.D (Full Catastrophe Living)","https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness-ebook/dp/B00C4BA3UK",20,0.5,0.5,1,0.1,0.45,0.5,0.5),
  new QuoteItem("The easiest and most effective way to begin cultivating mindfulness as a formal meditative practice is to simply focus your attention on your breathing and see what happens if you attempt to keep it there...","Jon Kabat-Zinn, Ph.D (Full Catastrophe Living)","https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness-ebook/dp/B00C4BA3UK",38,0.5,0.5,0.5,0.5,0.65,0.5,0.5),
  new QuoteItem("It would help us to be more mindful. All life is fascinating and beautiful when the veil of our routinized thinking lifts, even for a moment.","Jon Kabat-Zinn, Ph.D (Full Catastrophe Living)","https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness-ebook/dp/B00C4BA3UK",38,0.5,0.5,0.5,0.5,0.35,0.5,0.5),
  new QuoteItem("While we are whole ourselves as individual beings, we are also part of a larger whole, interconnected through our family and our friends and acquaintances to the larger society, and, ultimately, to the whole of humanity and life on the planet.","Jon Kabat-Zinn, Ph.D (Full Catastrophe Living)","https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness-ebook/dp/B00C4BA3UK",40,0.5,0.5,0.5,0.5,0.25,0.5,0.5),
  new QuoteItem("Wholeness and connectedness are what are most fundemental in our nature as living beings.","Jon Kabat-Zinn, Ph.D (Full Catastrophe Living)","https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness-ebook/dp/B00C4BA3UK",25,0.6,0.5,0.5,1,0,0.5,0.5),
  new QuoteItem("The process of uncovering in ourselves deep feelings of empathy, compassion, and love towards others has its own purifying effects on the mind and heart.","Jon Kabat-Zinn, Ph.D (Full Catastrophe Living)","https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness-ebook/dp/B00C4BA3UK",38,1,0.5,0.5,0.5,0.15,0.5,0.5),
  new QuoteItem("We are born with a beautiful open spirit, alive with innocence and resilience. But we bring this goodness into a difficult world.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",50,0.5,0.5,0.5,0.5,0.85,0.5,0.5),
  new QuoteItem("We can find true refuge within our own hearts and minds - right here, right now, in the midst of our moment-to-moment lives.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",38,0.75,0.5,0.5,0.5,0.25,0.5,0.5),
  new QuoteItem("No matter how challenging the situation, there is always a way to take refuge in a healing and liberating presence.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",38,0.5,0.5,1,0,0,1,0.5),
  new QuoteItem("We can't understand the nature of reality until we let go of controlling our experience.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",25,0.5,0.5,0.5,0.5,0.45,0.5,0.5),
  new QuoteItem("Presence is the awareness that is intrinsic to our nature. It is immediate and embodied, preceived through our senses.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",38,0.5,0.5,0.5,1,0.55,0.5,0.5),
  new QuoteItem("Tell them that they can trust their hearts and awareness to awaken in the midst of all circumstances.","Dalai Lama, Quoted by Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",50,0.5,0.5,0.5,0.5,0.65,0.5,0.5),
  new QuoteItem("The Tibetans teach that we should allow our minds to be as vast as the sky and our daily conduct to be as fine as a grain of sand.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",38,0.5,0.5,0.5,0.5,0.15,0.5,1),
  new QuoteItem("Participating in conscious community is a beautiful outer refuge, and it offers a powerful way to enter true refuge.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",25,0.5,0.5,0.5,0.5,0.95,0.5,0.5),
  new QuoteItem("Recognize what is happening, Allow life to be just as it is, Investigate inner experience with kindness, Non-identification [Rest in Natural Awareness]","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",38,0.5,1,1,0,0,0.5,0.5),
  new QuoteItem("Like the Buddha, our own healing and awakening unfolds in any moment in which we take refuge in our aliveness...","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",38,0.5,0.5,0.5,0.5,0.25,0.5,0.5),
  new QuoteItem("Like the Buddha touching the ground, we reclaim our life and spirit by planting ourselves again in the universe.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",38,0.5,0.5,0.5,0.5,0.15,1,0.5),
  new QuoteItem("We release the suffering that can accompany pain by relaxing our resistance to unplesant sensations and meeting them with an open, allowing, presence.","Tara Brach, Ph. D (True Refuge)","https://www.amazon.com/True-Refuge-Finding-Freedom-Awakened-ebook/dp/B009FKVXOS",38,1,1,0.5,0,0,1,0.5),
  new QuoteItem("Just as the well-tended compost becomes a flower garden, when we take care of and look deeply into our sorrow, it transforms into understanding and compassion.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,0.5,0.5,0.5,0.5,0.35,0.5,0.5),
  new QuoteItem("When we are in crisis or pain, we need to first take care of the immediate need, which is that crisis. Once our mindful energy has soothed our suffering, we can begin to look more deeply into its nature and sources.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",50,0.5,0.5,0.5,0.5,0.25,0.5,0.5),
  new QuoteItem("The important mindfulness practice of cultivating understanding means first of all undertanding suffering: the suffering inside us and the suffering of others.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,0.5,0.5,0.5,0.5,0.85,0.5,0.5),
  new QuoteItem("When suffering arises, the first thing to do is to stop, follow our breathing, and acknowledge it. Don't try to deny uncomfortable emotions or push them down.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,0.5,0.5,0.5,0.5,0.35,0.5,0.5),
  new QuoteItem("When you practice mindfulness of breathing, you know right away that you are alive, and that to be alive is a wonder.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,1,0.5,0.5,0,0,1,0.5),
  new QuoteItem("If we're feeding our suffering while we're walking, working, eating, or talking, we are making ourselves victims of the ghosts of the past, of the future, or our worries in the present. We're not living our lives.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",50,0.5,0.5,0.5,0.5,0.35,0.5,0.5),
  new QuoteItem("To be mindful means to be aware. It's the energy that knows what is happening in the present moment.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,0.5,0.5,0.5,0.5,0.45,0.5,0.5),
  new QuoteItem("If you know how to make good use of the mud, you can grow beautiful lotuses. If you know how to make good use of suffering, you can produce happiness.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,0.5,1,1,0,0,0.5,0.5),
  new QuoteItem("The way we start producing the medicine of mindfulness is by stopping and taking a conscious breath, ging our complete attention to our in-breath and our out-breath.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,0.5,0.5,0.5,0,0.25,1,0.5),
  new QuoteItem("Every life has its trials and tribulations. We can navigate them more skillfully when we don't waste time and energy shooting ourselves with a second arrow - such as dwelling on how much greener the grass is in our neighbor's yard looks, compared to ours.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",50,0.5,0.5,0.5,0.5,0.45,0.5,0.5),
  new QuoteItem("The first method of creating joy and happiness is to cast off, to leave behind. There is a kind of joy that comes from letting go.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,0.5,1,1,0,0,0.5,0.5),
  new QuoteItem("When you wake up in the morning, the first thing to do is to breathe and to become aware that you have twenty-four brand-new hours to live. This is a gift of life.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",60,0.5,0.5,0.5,0.5,0.85,0.5,0.5),
  new QuoteItem("The essence of our practice can be described as transforming suffering into happiness.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",25,1,1,1,0.25,0,1,0.5),
  new QuoteItem("With each breath, we ease suffering and generate joy. With each step, the flower of insight blooms.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,0.75,1,0.5,0.75,0.25,0.5,0.5),
  new QuoteItem("The first exercise is mindfulness of our breathing. 'Breathing in, I know I'm breathing in. Breathing out, I know I'm breathing out.' Bringing our awareness to our breathing, we stop all the thinking and focus only on our in-breath and out-breath.","Thich Nhat Hanh (No Mud, No Lotus)","https://www.amazon.com/No-Mud-Lotus-Transforming-Suffering-ebook/dp/B00LDYFFNY",38,1,0.5,1,0,0.35,1,0.5) ];
  
  setRef = (webcam: any) => {
    this.webcam = webcam;
  };

  constructor(props: any) {
    super(props);
  }

  onMediaError = (err: any) => {
    this.setState({errorText: 'Please allow webcam access and refresh your browser. QuietSky requires access to your webcam to function. Note that images from your webcam are used to select quotes are are not stored or transmitted.'});
    this.webcamIssue = true;
  }

  selectQuote = (age:number, angry:number, disgusted:number, fearful:number, happy:number, neutral:number, sad:number, surprised:number) => {
    let minDistance = Number.MAX_VALUE;
    let minDistanceQuote: QuoteItem;
    this.quoteItems.forEach((quoteItem:QuoteItem) => {
      let distance = quoteItem.distanceTo(age, angry, disgusted, fearful, happy, neutral, sad, surprised);
      if (distance < minDistance) {
        minDistance = distance;
        minDistanceQuote = quoteItem;
      }
    });
    this.setState({selectedQuote: minDistanceQuote!, errorText: undefined});
  }

  async componentDidMount() {

    if (!this.webcamIssue)
    {
      this.setState({errorText: 'Loading'});
    }

    await faceapi.loadSsdMobilenetv1Model(this.modelUrl);

    if (!this.webcamIssue)
    {
      this.setState({errorText: 'Loading.'});
    }

    await faceapi.loadAgeGenderModel(this.modelUrl);

    if (!this.webcamIssue)
    {
      this.setState({errorText: 'Loading..'});
    }

    await faceapi.loadFaceExpressionModel(this.modelUrl);

    if (!this.webcamIssue)
    {
      this.setState({errorText: 'Loading...'});
    }

    setTimeout(() => {

      if (!this.webcamIssue)
      {
        this.setState({errorText: undefined});
      }
      

      setInterval(() => {
        faceapi.detectAllFaces('frame')
          .withAgeAndGender()
          .withFaceExpressions()
          .then((data:any) => {

            if (data.length == 1) {

              if (!this.state.selectedQuote) {
                var age = data[0].age;
                var expression = data[0].expressions;
                this.selectQuote(age, expression.angry, expression.disgusted, expression.fearful, expression.happy, expression.neutral, expression.sad, expression.surprised);
              }

              var x = data[0].detection._box._x + data[0].detection._box._width / 2;
              var y = data[0].detection._box._y + data[0].detection._box._height / 2;
              var xNorm = data[0].detection._box._x / data[0].detection._imageDims._width;
              var yNorm = data[0].detection._box._y / data[0].detection._imageDims._height;
              this.setState({xNorm: xNorm, yNorm: yNorm});
            } else {

              if (data.length >= 2) {
                this.setState({errorText: 'QuietSky is a personal experience and multiple faces are not yet supported. Please approach the frame with only one person at the time.'});
              } else {
                if (this.state.selectedQuote) {
                  if (this.missingFaceCycles > 3) {
                    this.missingFaceCycles = 0;
                    this.setState({selectedQuote: undefined, errorText: undefined});
                  }
                  this.missingFaceCycles++;
                }
              }
            }
            
            return data;
        });
      },500);
    },1000);
  }

  render() {
    return (<div className="App inline">
    { this.state.selectedQuote && <Quote text={this.state!.selectedQuote!.quote} attribution={this.state!.selectedQuote!.author } moreLink={this.state!.selectedQuote!.moreLink } />  }
    { this.state.errorText && <ErrorDisplay text={this.state.errorText}></ErrorDisplay> }
    
    <SkyBackground x = {this.state.xNorm} y = {this.state.yNorm} frequency={1}></SkyBackground>
    <Webcam id="frame" audio={false} mirrored={true} onUserMediaError={this.onMediaError} ref={this.setRef}/>
    <img src={logo} className="logo-bottom-right absolute z-50 bottom-0 right-0 w-48 mr-12" alt="logo" />
  </div>)
  }
}

export default App;