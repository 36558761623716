import React from 'react';
import './SkyBackground.css';
import Noise from 'noise-library';
import { randomInt } from 'crypto';

// Uses Vector helper class: https://codepen.io/Tibixx/pen/rRBKBm.js
class Vector {

  x : number;
  y : number;

  constructor(x : number, y : number) {
    this.x = x;
    this.y = y;
  }

  add(v : Vector) {
    return new Vector(
      this.x + v.x,
      this.y + v.y);
  }

  addTo(v : Vector) {
    this.x += v.x;
    this.y += v.y;
  }

  sub(v : Vector) {
    return new Vector(
      this.x - v.x,
      this.y - v.y);
  }
  
  subFrom(v : Vector) {
    this.x -= v.x;
    this.y -= v.y;
  }
  
  mult(n:number) {
    return new Vector(this.x * n, this.y * n);
  }
  
  multTo(n:number) {
    this.x *= n;
    this.y *= n;
  }
  
  div(n:number) {
    return new Vector(this.x / n, this.y / n);
  }
  
  setAngle(angle:number) {
    var length = this.getLength();
    this.x = Math.cos(angle) * length;
    this.y = Math.sin(angle) * length;
  }
  
  setLength(length:number) {
    var angle = this.getAngle();
    this.x = Math.cos(angle) * length;
    this.y = Math.sin(angle) * length;
  }
  
  getAngle() {
    return Math.atan2(this.y, this.x);
  }
  
  getLength() {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  getLengthSq() {
    return this.x * this.x + this.y * this.y;
  }

  distanceTo(v : Vector) {
    return this.sub(v).getLength();
  }
  
  copy() {
    return new Vector(this.x, this.y);
  }
}

class SkyCanvas {

  time: number;
  cols: number = 40;
  rows: number = 10;
  field: Array<Array<Vector>> = [];
  canvas: HTMLCanvasElement;
  container: HTMLElement;
  box: DOMRect;
  ctx : CanvasRenderingContext2D;
  ctxScale: Vector = new Vector(1, 1);

  widthColorScaling : number = 0;
  heightColorScaling : number = 0;

  // From 0 to canvas width/height
  // can bind to mouse position
  // creates variation in flow field
  inputVector : Vector = new Vector(0, 0);

  tileSize : number = 40;
  tileRatio : number = 1;

  settings = {
      frequency: 0.01
  };

  constructor(canvas: HTMLCanvasElement, settings = {}) {
      this.settings = { ...this.settings, ...settings };

      this.time = 0;
      this.canvas = canvas;
      this.container = canvas.parentElement!;
      this.ctx = canvas.getContext('2d')!;
      this.box = canvas.getBoundingClientRect();

      this.cols = Math.ceil(this.container.clientWidth / this.tileSize);
      this.rows = Math.ceil(this.container.clientHeight / (this.tileSize * this.tileRatio));

      canvas.height = this.box.width * (this.container!.clientHeight / this.container!.clientWidth);
      canvas.width = this.box.width;

      this.ctxScale.x = canvas.width / this.cols;
      this.ctxScale.y = canvas.height / this.rows;

      this.widthColorScaling = 255 / this.cols;
      this.heightColorScaling = 255 / this.rows;

      this.widthColorScaling = 255 / this.cols;
      this.heightColorScaling = 255 / this.rows;

      this.build();
  }

  // Custom added vector to add mouse interaction
   manipulateVector = (vector : Vector, x : number, y : number) => {
    // Get root position of drawn element
    const pos = new Vector(
        x * this.ctxScale.x + 0.5 * this.ctxScale.x,
        y * this.ctxScale.y + 0.5 * this.ctxScale.y
    );

    const offsetEffect = new Vector(
        ((this.inputVector.x * 10 * this.canvas.clientWidth) - pos.x) / this.canvas.clientWidth,
        ((this.inputVector.y * 10 * this.canvas.clientHeight) - pos.y) / this.canvas.clientHeight
    );

    /*
    if (Math.random() > 0.9999) { 
      console.log(this.inputVector.x);
    }
    */
    //console.log(mouseEffect);

    vector.addTo(offsetEffect);
    // Cap to max 1
    if (vector.getLength() > 1) vector.setLength(1);
  };

  build() {
      // Prepare columns
      this.field = new Array(this.cols);
      for (let x = 0; x < this.cols; x++) {
          // Prepare rows
          this.field[x] = new Array<Vector>(this.rows);
          for (let y = 0; y < this.rows; y++) {
              // Prepare data
              this.field[x][y] = new Vector(0, 0);
          }
      }
  }

  onDraw = (vector : Vector, x : number, y : number) => {
    // Clear canvas

    if (x === 0 && y === 0) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    const xmove = vector.getLength() * Math.abs(vector.x);
    const ymove = vector.getLength() * Math.abs(vector.y);

    const red = Math.round(
        -20 * xmove + 80 * ymove + (50 - 0.6 * y * this.heightColorScaling)
    );
    const green = Math.round(
        180 * xmove + 20 * ymove - 60 + 0.4 * y * this.heightColorScaling
    );
    const blue = Math.round(
        50 * xmove +
            30 * ymove +
            (40 - 0.5 * y * this.heightColorScaling) +
            0.5 * y * this.heightColorScaling
    );

    this.ctx.save();

    this.ctx.fillStyle = `rgba(${red}, ${green}, ${blue}, 0.8)`;
    this.ctx.fillRect(x * this.ctxScale.x, y * this.ctxScale.y, this.ctxScale.x, this.ctxScale.y);

    this.ctx.restore();
  }

  update = (delta: number) => {
      this.time += delta;

      const updateTime = (this.time * this.settings.frequency) / 1000;
      for (let x = 0; x < this.field.length; x++) {
          for (let y = 0; y < this.field[x].length; y++) {

              const angle =
                  Noise.simplex3(x / 20, y / 20, updateTime) * Math.PI * 2;

              const length = Noise.simplex3(
                  x / 10 + 40000,
                  y / 10 + 40000,
                  updateTime
              );

              this.field[x][y].setAngle(angle);
              this.field[x][y].setLength(length);

              // Manipulate vector
              this.manipulateVector(this.field[x][y], x, y);

              // Render field
              this.onDraw(this.field[x][y], x, y);
          }
      }
  }
}

type SkyProps = {
  frequency: number,
  x: number,
  y: number
};

class SkyBackground extends React.Component<SkyProps> {

  updateFrequency:number = 1;
  normLoc: Vector = new Vector(0, 0);
  targetNormLoc: Vector = new Vector(0, 0);

  skyCanvas: any;

  constructor(props: SkyProps) {
    super(props);
    this.updateFrequency = props.frequency;
  }

  componentDidMount() {
    var component = this;
    
    var canvas = document.getElementById("skycanvas");

    this.skyCanvas = new SkyCanvas(canvas as HTMLCanvasElement, { frequency: 10 });

    function step() {

      // Gently move normLoc towards targetNormLoc
      if (component.normLoc.distanceTo(component.targetNormLoc) > 0.01) {
        component.normLoc.addTo(component.targetNormLoc.sub(component.normLoc).div(90));
      }

      component.skyCanvas.inputVector = component.normLoc;
      component.skyCanvas.update(1);
      
      window.requestAnimationFrame(step);
    }
    
    step();
  }

  componentDidUpdate() {
    this.updateFrequency = this.props.frequency;
    this.skyCanvas.settings.frequency = this.updateFrequency;
    
    this.targetNormLoc.x = this.props.x;
    this.targetNormLoc.y = this.props.y;
  }

  render() {
    return (
      <div className="background">
        <canvas id="skycanvas" className="skycanvas" width="100%" height="100%" />
      </div>
    );
  }
}

export default SkyBackground;
